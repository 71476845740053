import React from "react";
import { Container, Card, CardBody, Row, Col, Image} from "react-bootstrap";

const Projects= () => {
    return(
         <div className="mt-5 mb-5">
            <Container className="text-center">
                <p>Here you will find some of the projects I have worked on. Click the links below to visit each application.</p>
                <Row>
                    <Col sm='4'>
                    <Card className="bg-light">
                        <Image src="https://res.cloudinary.com/dxnwbl21t/image/upload/v1733264019/screenshot2_svubbw.png" fluid rounded/>
                        <CardBody className="text-center">
                            <a href="https://aallemond.github.io/nba-gambit">NBA Gambit</a>
                        </CardBody>
                    </Card>
                    </Col>

                    <Col sm='4'>
                    <Card className="bg-light">
                        <Image src="https://res.cloudinary.com/dxnwbl21t/image/upload/v1733264792/Screenshot_2024-12-03_at_4.26.15_PM_q0ccj5.png" fluid rounded />
                        <CardBody className="text-center">
                            <a href="https://aallemond.github.io/password-generator/">Password Generator</a>
                        </CardBody>
                    </Card>
                    </Col>

                    <Col sm='4'>
                        <Card className="bg-light">
                        <Image src="https://res.cloudinary.com/dxnwbl21t/image/upload/v1733265142/Screenshot_2024-12-03_at_4.31.59_PM_gtwbrj.png" fluid rounded/>
                            <CardBody className="text-center">
                                <a href="https://github.com/jsdrever/smart-vote">Informed Voter</a>
                            </CardBody>
                        </Card>
                    </Col>

                    <Row className="justify-content-md-center mt-4">
                        <Col sm='4'>
                        <Card className="bg-light">
                        <Image src="https://res.cloudinary.com/dxnwbl21t/image/upload/v1733265640/Screenshot_2024-12-03_at_4.40.15_PM_jfahsy.png"fluid rounded />
                            <CardBody className="text-center">
                                <a href="https://www.bryantlawfirmla.com/">Bryant Law Firm Website</a>
                            </CardBody>
                        </Card>
                        </Col>

                        <Col sm='4'>
                        <Card>
                            <Image src="https://res.cloudinary.com/dxnwbl21t/image/upload/v1733266427/Screenshot_2024-12-03_at_4.53.25_PM_dxjd30.png" fluid rounded/>
                            <CardBody className="text-center">
                                <a href="https://rocky-harbor-30031.herokuapp.com/aboutUs">GreenUp!</a>
                            </CardBody>
                        </Card>
                        </Col>
                    </Row>

                   
                </Row>
                
            </Container>
            
        </div>
    )
}

export default Projects;