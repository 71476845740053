import React from "react";
import { CardBody, CardSubtitle, CardTitle } from "react-bootstrap";
import { Card } from "react-bootstrap";
import { Container, Row, Col} from "react-bootstrap";




const Contact = () => {
    return(
     <div className="mt-5 mb-5" >
      <Container>
        <Row className="justify-content-md-center">
          <Col sm='6'>
            <Card className="bg-light">
              <img 
              alt='LinkedIn Logo'
              src='https://res.cloudinary.com/dxnwbl21t/image/upload/v1724897419/linkedin-logo-linkedin-icon-transparent-free-png_ler0ps.webp'
              />
            <CardBody className="text-center" tag='h5'>
              <CardTitle>LinkedIn</CardTitle>
              <CardSubtitle>
               <a href="https://linkedin.com/in/andrew-allemond1992">LinkedIn.com/andrew-allemond1992</a> 
                </CardSubtitle>
            </CardBody>
            </Card>
          </Col>
          </Row>

        <Row className="justify-content-md-center">
          <Col className='mt-4'sm='6'>
            <Card className="bg-light">
              <img
              alt="Mail"
              src="https://res.cloudinary.com/dxnwbl21t/image/upload/v1724898773/emailPic_ucffnk.avif"
              />
              <CardBody className="text-center" tag='h5'>
                <CardTitle>E-Mail</CardTitle>
                <CardSubtitle>allemondandrew@gmail.com</CardSubtitle>
              </CardBody>
            </Card>
          </Col>

          {/* <Col sm='4'>
            <Card style={{width:'18 rem'}}>
              <img alt="Github Logo" src="https://res.cloudinary.com/dxnwbl21t/image/upload/v1733257523/smartphone-blue-icon-by-Vexels_tgrbyh.svg"></img>
            <CardBody className="text-center" tag='h5'>
              <CardTitle>Phone</CardTitle>
              <CardSubtitle m='5'>512-686-4707</CardSubtitle>
            </CardBody>
            </Card>
          </Col> */}


        </Row>
      </Container>
     </div>
    )
}


export default Contact