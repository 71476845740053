import React from "react";
import { Image, Container, Row, Col} from "react-bootstrap";



const Home = () => {
    return(
        <div>
        <Container>
           
            <Row>
                
                <Col sm='8' className="mt-5 mb-5 ">
                    <Image src='https://res.cloudinary.com/dxnwbl21t/image/upload/v1724797911/IMG_9760_jqajpf.png' fluid rounded />
                 </Col>

                 <Col className="mt-5">
                    <p>
                        My name is Andrew Allemond and I am an aspiring full-stack web developer living in Austin, Texas. I was born in Lake Charles, Louisiana where I attended McNeese State University studying History and Social Studies Education from 2010 to 2012. In 2021 I relocated full time to Austin in hopes of finding a new career where I can grow and succeed.</p> 
                        
                        <p>It was there I decided to attend the University of Texas at Austin's Full-Stack Web Developer Bootcamp to hopefully turn a passing interest into something I could use to earn my living. I started with little to no knowledge of programming languages, coding, etc. and ended with an entirely new skillset and have done things I could only ever dream of doing before. Since the conclusion of the course I have been constantly striving to further my understating of the skills and concepts it taught me as well as branching out and educating myself in new programming languages along with keeping up to date on the ones I know to keep up with the ever changing landscape of web development.</p>

                         <p>In my spare time you can find me at my local skatepark ,at home with my cat where I enjoy playing music, video games, or just a quiet evening watching movies or a sports game.</p>

                         <div className="mt-10">
                           <p>Special Skills</p>
                        
                           <ul>
                            <li>
                                HTML
                            </li>
                            <li>
                                CSS (and other frameworks i.e. bootstrap, tailwind, etc.)
                            </li>
                            <li>
                                JavaScript(jQuery, React, Handlebars, Node.js, Express.js)
                            </li>
                            <li>
                                Databases (mySQL, NoSQL)
                            </li>
                            <li>
                                Web Hosting
                            </li>
                           </ul>
                           </div>
                 </Col>
                 
                    
                 
            
             </Row>
             
        </Container>

       

       
        </div>
    )
}

export default Home;