import React from "react";
import { Container, Navbar, NavbarBrand, } from "react-bootstrap";



const Footer =() =>{
    return(
        <Navbar bg="secondary" data-bs-theme="dark">
            <Container className="justify-content-center">
                <NavbarBrand>
                    
                </NavbarBrand>
            </Container>


        </Navbar>
        
    )
}


export default Footer;