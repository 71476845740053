import React from "react";
import { Link } from "react-router-dom";
import { Navbar, Nav, Container, NavLink, NavbarBrand, NavbarToggle, NavbarCollapse } from "react-bootstrap";





const Header = () => {
    return(
  
        <Navbar expand='sm' bg="light" data-bs-theme="light">
            <NavbarToggle aria-controls="responsive-navbar-nav"/>
            <NavbarCollapse id='responsive-navbar-nav'>
            <Container>
                <NavbarBrand>Andrew Allemond</NavbarBrand>
            </Container>

            <Container>
                    <Nav className="justify-content-end">
                        <NavLink as={Link}to='/'>About Me</NavLink>
                        <Nav.Link as={Link} to='/projects'>Projects</Nav.Link>
                        <Nav.Link as={Link}to='/contact'>Contact Me</Nav.Link>
                    </Nav>
                    </Container>
                </NavbarCollapse>
            
        </Navbar>
       
    )
}

export default Header;